import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to represent loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust delay as needed

    // Cleanup timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <section className="p-8 min-h-screen flex items-center justify-center bg-gray-50">
        <Loader />
      </section>
    );
  }
  return (
    <main className="p-8 bg-gray-100 min-h-screen">
      <section className="p-6 text-gray-800 rounded-lg shadow-md m-4 container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>

        <h2 className="text-2xl font-semibold mb-4">Welcome to Vision Ward!</h2>
        <p className="mb-4">
          At Vision Ward, our goal is to empower individuals and organizations
          through innovative solutions and strategic insights. We are dedicated
          to helping you achieve your vision and reach your full potential.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
        <p className="mb-4">
          Founded in [Year], Vision Ward was created with the mission to provide
          cutting-edge tools and support that drive success and growth. We
          believe in the power of strategic vision and are committed to offering
          resources that help our clients realize their goals.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
        <p className="mb-4">
          Our team is composed of experienced professionals with expertise in
          various fields, including strategic planning, business development,
          and innovation. We work collaboratively to deliver high-quality
          solutions and support tailored to your needs.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Our Services</h2>
        <ul className="list-disc list-inside mb-4">
          <li className="mb-2">
            <strong>Consulting Services:</strong> Expert consulting to help you
            develop and execute effective strategies.
          </li>
          <li className="mb-2">
            <strong>Training Programs:</strong> A range of training programs
            designed to enhance your skills and capabilities.
          </li>
          <li className="mb-2">
            <strong>Resources and Tools:</strong> Access to valuable resources
            and tools to support your growth and success.
          </li>
        </ul>

        <p>
          Thank you for choosing Vision Ward. We look forward to supporting your
          journey towards achieving your vision.
        </p>
      </section>
    </main>
  );
};

export default About;
