import { useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className=" text-primary-color p-4">
      <nav className="container mx-auto flex justify-between items-center flex-wrap">
        <img
          src="/navbar.png"
          className="w-[150px] mb-4 md:mb-"
          alt="visionward.online"
        />

        <button className="text-primary-color md:hidden" onClick={toggleMenu}>
          &#9776; {/* This is a hamburger icon */}
        </button>
        <div
          className={`flex flex-col md:flex-row items-center gap-4 md:gap-6 w-full md:w-auto ${
            isMenuOpen ? "block" : "hidden"
          } md:flex`}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              `font-bold text-lg ${
                isActive ? "text-accent-pink" : "text-primary-color"
              } 
              hover:text-secondary-color transition-all duration-300`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/privacy-policy"
            className={({ isActive }) =>
              `${isActive ? "text-accent-pink" : "text-primary-color"} 
                font-bold hover:text-secondary-color transition-all duration-300`
            }
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `${isActive ? "text-accent-pink" : "text-primary-color"} 
                font-bold hover:text-secondary-color transition-all duration-300`
            }
          >
            About
          </NavLink>
          <NavLink
            to="/term&condition"
            className={({ isActive }) =>
              `${isActive ? "text-accent-pink" : "text-primary-color"} 
                font-bold hover:text-secondary-color transition-all duration-300`
            }
          >
            Terms and Conditions
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              `${isActive ? "text-accent-pink" : "text-primary-color"} 
                font-bold hover:text-secondary-color transition-all duration-300`
            }
          >
            Contact Us
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Header;
