import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Games from "../Const/Games";
import Loader from "./Loader";

// Function to get random games
const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const Play = () => {
  const [searchParams] = useSearchParams();
  const link = searchParams.get("play");
  const [loading, setLoading] = useState(true);
  const [selectedGames, setSelectedGames] = useState([]);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [currentGame, setCurrentGame] = useState(null);
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const games = getRandomGames(Games, 12);
      setSelectedGames(games);
      setLoading(false);
      setIframeLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (link) {
      const selectedGame = Games.find((game) => game.gameurl === link);
      setCurrentGame(selectedGame || null);
    }
  }, [link]);

  const handleGameClick = (gameLink) => {
    setIframeLoading(true);
    navigate(`/play?play=${gameLink}`);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
  };

  useEffect(() => {
    if (link) {
      setIframeLoading(true);
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.onload = () => {
          setIframeLoading(false);
        };
      }
    }
  }, [link]);

  return (
    <section className="p-8 min-h-screen flex flex-col items-center">
      {loading ? (
        <div className="flex items-center justify-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <div className="w-full max-w-screen-xl mx-auto mb-8">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
              {/* Center Column */}
              <div className="col-span-12 relative">
                {iframeLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-80 z-10">
                    <Loader />
                  </div>
                )}
                <iframe
                  src={link || ""}
                  title="Play Screen"
                  className="w-full h-[80vh] md:h-[60vh] border-none"
                  ref={iframeRef}
                ></iframe>
              </div>
            </div>
          </div>

          <div className="w-full max-w-screen-xl mx-auto">
            {currentGame && (
              <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                <h2 className="text-4xl font-bold mb-4">{currentGame.title}</h2>
                <div
                  className="text-gray-700 text-lg mb-4"
                  dangerouslySetInnerHTML={{ __html: currentGame.description }}
                ></div>
                <div
                  className="text-gray-600 text-base"
                  dangerouslySetInnerHTML={{ __html: currentGame.sec_desc }}
                ></div>
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {selectedGames.map((game, index) => (
                <div
                  key={index}
                  onClick={() => handleGameClick(game.gameurl)}
                  className="relative cursor-pointer group rounded-lg shadow-lg overflow-hidden bg-white transition-transform transform hover:scale-105"
                >
                  <img
                    src={game.image}
                    alt={game.title}
                    className="w-full h-56 object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white text-xl font-semibold p-4 text-center">
                    {game.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Play;
